import { HttpClient } from "@/plugins/httpClient";
import { OAuthSignIn, OAuthProviderType } from "@/plugins/firebase";
import store from "@/stores";
import router from "@/router";

var httpClient = new HttpClient(`${process.env.VUE_APP_API_URL}/user`);

export async function login(email, password) {
  var body = {
    email,
    password,
  };
  return await httpClient.post("/login", {}, body);
}

export async function oauth2Login(provider = OAuthProviderType.Google) {
  var token = await OAuthSignIn(provider);
  var result = await httpClient.post("/login/oauth2", null, null, { token });
  if (result.token) return result;
  store.commit("Signup/SET_OAUTH_TOKEN", token);
  router.push("/register");
}
